import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./section1.module.css";
import Img1 from "../../../assets/img1.png";

import Img2 from "../../../assets/img2.png";
import iphone2 from "../../../assets/DevicesGroup.png";

import LG from "../../../assets/LG.png";
import samsung from "../../../assets/samsung-smart-tv.png";
import playMarket from "../../../assets/google-play.png";
import appStore from "../../../assets/app-store.png";
import androidTV from "../../../assets/android-tv.png";




import { useTranslation } from "react-i18next";
import { useSprings, animated, config } from "@react-spring/web";

const Section1 = ({ isInView, handleGoToSignUp }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const imageRef = useRef(null);

  const configImg = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: config.default,
  };
  const configText = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: config.default,
  };
  const configLine = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 3, tension: 250, friction: 80 },
  };

  const [image1Styles, image2Styles, image3Styles] = useSprings(3, [
    { ...configImg, delay: 100 },
    { ...configImg, delay: 200 },
    { ...configImg, delay: 300 },
  ]);
  const [text1Styles, text2Styles] = useSprings(2, [
    { ...configText, delay: 100 },
    { ...configText, delay: 200 },
  ]);

  const [line1Styles, line2Styles] = useSprings(2, [
    { ...configLine, delay: 100 },
    { ...configLine, delay: 200 },
  ]);

  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

  // Update mouse coordinates on mouse move
  const handleMouseMove = (e) => {
    const image = imageRef.current; // Get the image element from the ref
    const rect = image.getBoundingClientRect(); // Get the image's bounding rect
    const offsetX = e.clientX - rect.left; // Calculate mouse position relative to the image
    const offsetY = e.clientY - rect.top;
    const centerX = rect.width / 2; // Calculate image center
    const centerY = rect.height / 2;
    const maxDistance = 1.3; // Maximum distance for image movement

    const deltaX = (offsetX - centerX) * (maxDistance / centerX);
    const deltaY = (offsetY - centerY) * (maxDistance / centerY);

    const centerXImg = image.offsetHeight / 2;
    const centerYImg = image.offsetWidth / 2;

    const d3Style = `
    rotateX(${-(e.offsetY - centerXImg) / 5}deg)
    rotateY(${(e.offsetX - centerYImg) / 5}deg)
    `;

    setMouseCoords({ x: deltaX, y: deltaY, d3Style });
  };

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 800) {
  //     return {
  //       transform: ` scale(${screenSize.height / 800}) `,
  //       marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       marginTop: `${-100}px`,
  //     };
  //   }
  // };


  const stores = [
    { img: androidTV, link: 'https://play.google.com/store/apps/details?id=com.marshall7.TOPTV', tooltip: 'Search for "RippleTV" on the Play Market on your TV' },
    { img: playMarket, link: 'https://play.google.com/store/apps/details?id=com.marshall7.TOPTV', tooltip: 'Search for "RippleTV" on the Play Market'},
    { img: appStore, link: 'https://apps.apple.com/id/app/ripple-tv/id6502562196', tooltip: 'Search for "Ripple-TV" on the App Store' },
    { img: samsung, link: 'https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/', tooltip: 'Search for "RippleTV" on the Smart Hub on your TV'},
    { img: LG, link: 'https://us.lgappstv.com/main/tvapp/detail?appId=1230439&catCode1=&moreYn=N&cateYn=N&orderType=0&headerName=%EC%A0%84%EC%B2%B4&appRankCode=&sellrUsrNo=&curPage=1', tooltip: 'Search for "RippleTV" on the ContentStore on your TV' },
  ]

  return (
    <>
    
      
      <div
        className={styles.container}
        onMouseMove={handleMouseMove}
        ref={ref}
      >
        <animated.img
        className={styles.Img1}
        src={Img1}
        style={{
          ...image1Styles
        }}
      />
        <animated.img
        className={styles.Img2}
        src={Img2}
        alt="iphone"
        style={{
          transform: `translate3d(${mouseCoords.x}px, ${mouseCoords.y}px, 0)`,
          ...image2Styles,
        }}
      />
        <div className={styles.texts}>
          <animated.div className={styles.h2Container} style={text1Styles}>
            <h2>{t(`user.1.title`)}</h2>
          </animated.div>
          <div className={styles.pcLines}>
            <animated.div
              className={styles.firstLine}
              style={line1Styles}
            ></animated.div>
            <animated.div
              className={styles.secondLine}
              style={line2Styles}
            ></animated.div>
          </div>
          {/* <div className={styles.mobileLines}>
            <animated.div
              className={styles.firstLine}
              style={line1Styles}
            ></animated.div>
            <animated.div
              className={styles.secondLine}
              style={line2Styles}
            ></animated.div>
          </div> */}
          <div className={styles.LongTextContainer}>
            <animated.div className={styles.longText} style={text2Styles}>
              <p>{t(`user.1.longText`)}</p>
            </animated.div>
            <button onClick={handleGoToSignUp} className={styles.crtAcc}>Create Account</button>
          </div>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.images}>
            <animated.div style={{...image3Styles, display: 'grid'}}>
              <img
                ref={imageRef}
                className={styles.Iphone}
                src={iphone2}
                alt="iphone"
                style={{
                  transform: mouseCoords.d3Style,
                }}
              />
            </animated.div>
          </div>
        </div>
      </div>
      <div className={styles.footCont}>
        <div className={styles.footer} >
          {stores.map((item, i) => (
            <a key={i} onClick={(e)=>!item.link && e.preventDefault()} href={item.link} target="_blank" title={item.tooltip ?? "Cooming Soon"} className={styles.logosCont}>
              <img
                className={styles.logos}
                src={item.img}
                alt={item.img}
              />
            </a>
          ))}
        </div>
        
      </div>
    </>
  );
};

export default Section1;
