import React, { useEffect, useState } from "react";
import styles from "./section7.module.css";
import { useSpring, animated } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import img11 from "../../../assets/img11.png";
import img12 from "../../../assets/img12.png";

const Section7 = ({ isInView }) => {
  const [selected, setSelected] = useState("Streamer");
  const { t } = useTranslation();
  // const [screenSize, setScreenSize] = useState({
  //   height: window.innerHeight,
  //   width: window.innerWidth,
  // });

  const select = ["Middleware", "Transcoder", "Streamer", "Archiver", "VOD"];

  const fakeDateBox = [
    {
      title: "Streamer Licence",
      streams: "FREE/10 streams",
      licence: "Licence Gives You the oppotunities like",
      bulletedList: [
        "Whether you need to do aaaaa aaaaa a a a a a a as",
        "Whether you need to do",
        "Whether you need to do",
        "Whether you need to do",
      ],
    },
    {
      title: "Streamer Licence",
      streams: "FREE/10 streams",
      licence: "Licence Gives You the oppotunities like",
      bulletedList: [
        "Whether you need to do",
        "Whether you need to do",
        "Whether you need to do",
        "Whether you need to do",
      ],
    },
    {
      title: "Streamer Licence",
      streams: "FREE/10 streams",
      licence: "Licence Gives You the oppotunities like",
      bulletedList: [
        "Whether you need to do",
        "Whether you need to do",
        "Whether you need to do",
        "Whether you need to do",
      ],
    },
  ];

  const springLineTitleLeft = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
    delay: 900,
  });
  const springLineTitleRight = useSpring({
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 3, tension: 60, friction: 30 },
    delay: 900,
  });

  const underlineProps = useSpring({
    to: {
      left:
        selected === select[0]
          ? "0%"
          : selected === select[1]
          ? "20%"
          : selected === select[2]
          ? "40%"
          : selected === select[3]
          ? "60%"
          : "80%",
    },
    config: { tension: 300, friction: 40 },
  });

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 900) {
  //     return {
  //       transform: ` scale(${screenSize.height / 900}) `,
  //       top: "-20px",
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // position: "relative",
  //       // top: `${(850 / screenSize.height) * -10}px`,
  //     };
  //   }
  // };

  const prop1 = {
    transform: ` scale(${window.innerHeight / 900}) `,
    top: "-20px",
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 900
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
      ? prop2
      : {};

      const [display, setDisplay] = useState(false)
   
      useEffect(()=>{
        if(isInView){
          setDisplay("inherit")
        } else {
          setTimeout(()=>{
            setDisplay("none")
          }, 600)
        }
      }, [isInView])

  return (
    <div className={styles.content} style={{...styleResult, display: display}}>
      <div className={styles.titleContainer}>
        <animated.div
          className={styles.titleFirstLine}
          style={springLineTitleLeft}
        ></animated.div>
        <div className={styles.title}>
          <p>{t(`provider.7.Licences`)}</p>
        </div>
        <animated.div
          className={styles.titleSecondLine}
          style={springLineTitleRight}
        ></animated.div>
      </div>
      <div className={styles.container}>
        <div className={styles.text}>
          <p>{t(`provider.6.text`)}</p>
        </div>
        <div className={styles.select}>
          {select.map((item, ind) => {
            return (
              <div
                key={ind}
                className={styles.selectItem}
                onClick={() => setSelected(item)}
              >
                <p>{item}</p>
              </div>
            );
          })}
          <animated.div
            className={styles.underline}
            style={underlineProps}
          ></animated.div>
        </div>
        <div
          className={styles.boxes}
          onTouchStart={(e) => {
            e.stopPropagation();
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
          }}
        >
          {fakeDateBox?.map((item, ind) => {
            return (
              <div className={styles.boxContainer} key={ind}>
                <div className={styles.box}>
                  <p className={styles.boxTitle}>{item?.title}</p>
                  <div className={styles.boxLine}></div>
                  <p className={styles.stream}>{item?.streams}</p>
                  <div className={styles.boxLine}></div>
                  <p className={styles.licence}>{item?.licence}</p>

                  <div className={styles.listContainer}>
                    {item?.bulletedList?.map((list, ind) => {
                      return (
                        <p key={ind} className={styles.list}>
                          {list}
                        </p>
                      );
                    })}
                  </div>

                  <button className={styles.boxBtn} onClick={() => {}}>
                    <p>Purchase</p>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.bgImages1}>
        <img className={styles.img11} src={img11} alt="" />
        <img className={styles.img12} src={img12} alt="" />
      </div>
      <div className={styles.bgImages2}>
        {/* <img className={styles.img11_2} src={img11} alt="" /> */}
      </div>
    </div>
  );
};

export default Section7;
