export const extractTokenAndEmailFromURL = (url) => {
    const regex = /token=([^&]+)&email=([^&]+)/;
    const matches = url.match(regex);
  
    if (matches && matches.length === 3) {
      const token = matches[1];
      const email = matches[2];
      return { token, email };
    }
  
    return null;
  }