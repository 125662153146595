import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as TLogo } from "../assets/TopLogo.svg"
import styles from "./header.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Burger } from "../assets/burger.svg";

const Header = ({ children, setPage, page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const isPrivacy = window.location.pathname.includes('privacy-policy')
  const isConfirmEmail = window.location.pathname.includes('confirmEmail')
  return (
    <div>
      <header id='header' className={styles.header}>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} width={"100%"} height={"100%"} />
          </div>
          <div className={styles.navigation}>
            <span
              className={page === "user" ? styles.activeUser : styles.user}
              onClick={() => { setPage("user"); (isPrivacy || isConfirmEmail) && window.location.replace('/'); }}
            >
              {t(`header.user`)}
            </span>
            <span
              className={page !== "user" ? styles.activeProvider : styles.provider}
              onClick={() => { setPage("provider"); (isPrivacy || isConfirmEmail) && window.location.replace('/') }}
            >
              {t(`header.provider`)}
            </span>
          </div>
        </div>
      </header>
      {children}
    </div>
  );
};
export default Header;
