import React, { useEffect, useState } from "react";
import styles from "./section2.module.css";
import { ReactComponent as Circle } from "../../../assets/circle.svg";
import { ReactComponent as Play } from "../../../assets/play.svg";
import { useTranslation } from "react-i18next";

const Section2 = ({ isInView }) => {
  const { t } = useTranslation();

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 900) {
  //     return {
  //       transform: ` scale(${screenSize.height / 900}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   }
  // };

  const prop1 = {
    transform: ` scale(${window.innerHeight / 900}) `,
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 900
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
      ? prop2
      : {};

      const [display, setDisplay] = useState(false)
      useEffect(()=>{
        if(isInView){
          setDisplay("inherit")
        } else {
          setTimeout(()=>{
            setDisplay("none")
          }, 600)
        }
      }, [isInView])

  return (
    <div className={styles.content} style={{...styleResult, display: display}}>
      <div className={styles.container}>
        <p className={styles.title}>{t(`provider.2.title`)}</p>
        <div className={styles.videoContainer}>
          <div className={styles.playContainer}>
            <Circle
              className={styles.Circle}
              // width={`${(windowSize.width / 100) * 24}`}
              width={"100%"}
            />
            <Play
              className={styles.Play}
              // width={`${(windowSize.width / 100) * 24}`}
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
