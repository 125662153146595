import React, { useEffect, useMemo, useState } from "react";
import styles from "./section6.module.css";
import img11 from "../../../assets/img11.png";
import img12 from "../../../assets/img12.png";
import img9 from "../../../assets/img9.png";
import img10 from "../../../assets/img10.png";
import ArrowDown from "../../../assets/ArrowDown.png";
import download from "../../../assets/download.png";
import { useSpring, useSprings, animated } from "@react-spring/web";
import { useTranslation } from "react-i18next";

const Section6 = ({ isInView }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("Middleware");

  const sizeIndex = window.innerWidth / 100 + window.innerHeight / 100;

  const Btn = ({ title }) => {
    return (
      <div
        className={`${styles.btn} ${selected === title ? styles.selectedBtn : ""
          }`}
        onClick={() => {
          setSelected(title);
        }}
      >
        <p>{title}</p>
        <img className={styles.ArrowDown} src={ArrowDown} alt="" />
      </div>
    );
  };

  const fakeDate = {
    Transcoder: [
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
    ],
    VOD: [
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
    ],
    Middleware: [
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
    ],
    Streamer: [{ Versions: "Versions", Name: "Name", Download: "url" }],
    Archiver: [
      { Versions: "Version", Name: "Name", Download: "url" },
      { Versions: "Version", Name: "Name", Download: "url" },
    ],
  };

  const sizes = useMemo(() => {
    if (window.innerWidth < 1400) {
      return {
        width: {
          w1: `${sizeIndex * 4}px`,
          w2: `${sizeIndex * 4}px`,
          w4: `${sizeIndex * 1.5}px`,
          w5: `${sizeIndex * 3}px`,
          w6: `${sizeIndex * 8.5}px`,
          w7: `${sizeIndex * 9.5}px`,
        },
        height: {
          h1: `${sizeIndex * 1.5}px`,
          h2: `${sizeIndex * 2.2}px`,
          h3: `${sizeIndex * 2.2}px`,
          h4: `${sizeIndex * 1}px`,
          h5: `${sizeIndex * 1}px`,
          h6: `${sizeIndex * 2.5}px`,
          h7: `${sizeIndex * 1.5}px`,
        },
      };
    } else if (window.innerWidth > 1400) {
      return {
        width: {
          w1: `80px`,
          w2: `125px`,
          w4: `45.5px`,
          w5: `75.5px`,
          w6: `210px`,
          w7: `250px`,
        },
        height: {
          h1: `40.5px`,
          h2: `55px`,
          h3: `60px`,
          h4: `27.5px`,
          h5: `35.5px`,
          h6: `62px`,
          h7: `36px`,
        },
      };
    }
  }, [window.innerWidth]);

  const springLineHeight1 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h1 : "0px",
    },
    config: { duration: 300 },
    delay: 900,
  });

  const springLineWidth1 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w1 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },
    delay: 1200,
  });

  const springLineHeight2 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h2 : "0px",
    },
    config: { duration: 300 },
    delay: 900,
  });

  const springLineWidth2 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w2 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },
    delay: 1200,
  });

  const springLineHeight3 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h3 : "0px",
    },
    config: { duration: 300 },
    delay: 1200,
  });

  const springLineHeight4 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h4 : "0px",
    },
    config: { duration: 300 },
    delay: 900,
  });

  const springLineWidth4 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w4 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },
    delay: 1200,
  });

  const springLineHeight5 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h5 : "0px",
    },
    config: { duration: 300 },
    delay: 900,
  });

  const springLineWidth5 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w5 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },
    delay: 1200,
  });

  const springLineHeight6 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h6 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },

    delay: 1200,
  });

  const springLineWidth6 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w6 : "0px",
    },
    config: { duration: 300 },
    delay: 900,
  });

  const springLineHeight7 = useSpring({
    from: {
      height: "0px",
    },
    to: {
      height: isInView ? sizes?.height.h7 : "0px",
    },
    config: { mass: 1, tension: 100, friction: 20 },

    delay: 900,
  });

  const springLineWidth7 = useSpring({
    from: {
      width: "0px",
    },
    to: {
      width: isInView ? sizes?.width.w7 : "0px",
    },
    config: { duration: 300 },
    delay: 1200,
  });

  const springOpacityBtn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: isInView ? 1 : 0,
    },
    config: { mass: 1, tension: 100, friction: 20 },
    delay: 1600,
  });

  const springLineTitleLeft = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
    delay: 900,
  });
  const springLineTitleRight = useSpring({
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 3, tension: 60, friction: 30 },
    delay: 900,
  });

  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const scale = () => {
    if (isInView && screenSize.width > 1400 && screenSize.height < 950) {
      return {
        transform: ` scale(${screenSize.height / 950}) `,
        top: "-30px",
        // marginTop: `${-100}px`,
      };
    } else if (
      isInView &&
      screenSize.width > 700 &&
      screenSize.width < 1400 &&
      screenSize.height < 630
    ) {
      return {
        transform: ` scale(${screenSize.height / 630}) `,
        // marginTop: `${-100}px`,
      };
    }
  };

  // const prop1 = {
  //   transform: ` scale(${window.innerHeight / 950}) `,
  //   top: "-30px",
  // };
  // const prop2 = {
  //   transform: ` scale(${window.innerHeight / 630}) `,
  // };

  // const styleResult =
  //   window.innerWidth > 1400 && window.innerHeight < 950
  //     ? prop1
  //     : window.innerWidth > 700 &&
  //       window.innerWidth < 1400 &&
  //       window.innerHeight < 630
  //     ? prop2
  //     : {};

  const [display, setDisplay] = useState(false)
  useEffect(()=>{
    if(isInView){
      setDisplay("inherit")
    } else {
      setTimeout(()=>{
        setDisplay("none")
      }, 600)
    }
  }, [isInView])

  return (
    <div className={styles.content} style={{...scale(), display: display}}>
      <div className={styles.titleContainer}>
        <animated.div
          className={styles.titleFirstLine}
          style={springLineTitleLeft}
        ></animated.div>
        <div className={styles.title}>
          <p>{t(`provider.6.Downloads`)}</p>
        </div>
        <animated.div
          className={styles.titleSecondLine}
          style={springLineTitleRight}
        ></animated.div>
      </div>
      <div className={styles.container}>
        <div className={styles.text}>
          <p>{t(`provider.6.text`)}</p>
        </div>
        <div className={styles.map}>
          <div className={styles.line1}>
            <animated.div style={springOpacityBtn}>
              <Btn title={"Transcoder"} />
            </animated.div>
            <animated.div style={springOpacityBtn}>
              <Btn title={"VOD"} />
            </animated.div>
          </div>
          <div className={styles.line2}>
            {/* <animated.div style={springOpacityBtn}> */}
            <Btn title={"Middleware"} />
            {/* </animated.div> */}
          </div>
          <div className={styles.line3}>
            <animated.div style={springOpacityBtn}>
              <Btn title={"Streamer"} />
            </animated.div>
            <animated.div style={springOpacityBtn}>
              <Btn title={"Archiver"} />
            </animated.div>
          </div>
          <animated.div
            className={styles.border1}
            style={{ ...springLineHeight1, ...springLineWidth1 }}
          ></animated.div>
          <animated.div
            className={styles.border2}
            style={{ ...springLineHeight2, ...springLineWidth2 }}
          ></animated.div>
          <animated.div
            className={styles.border3}
            style={springLineHeight3}
          ></animated.div>
          <animated.div
            className={styles.border4}
            style={{ ...springLineHeight4, ...springLineWidth4 }}
          ></animated.div>
          <animated.div
            className={styles.border5}
            style={{ ...springLineHeight5, ...springLineWidth5 }}
          ></animated.div>
          <animated.div
            className={styles.border6}
            style={{ ...springLineHeight6, ...springLineWidth6 }}
          ></animated.div>
          <animated.div
            className={styles.border7}
            style={{ ...springLineHeight7, ...springLineWidth7 }}
          ></animated.div>
        </div>
        <div
          className={styles.tableContainer}
          onTouchStart={(e) => {
            e.stopPropagation();
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
          }}
        >
          <p className={styles.tableText}>{t(`provider.6.tableText`, {selected: selected})}</p>
          <div onWheel={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} className={styles.table}>
            <table>
              <thead className={`${styles.tableTitle} `}>
                <tr>
                  <td>{t(`provider.6.Versions`)}</td>
                  <td>{t(`provider.6.Name`)}</td>
                  <td>{t(`provider.6.Download`)}</td>
                </tr>
              </thead>
              <tbody>
                {fakeDate[selected]?.map((item, ind) => {
                  return (
                    <tr key={ind} className={styles.otherTr}>
                      <td>
                        <div className={styles.VersionsContainer}>
                          <div className={styles.greenCircle}></div>
                          {`${item.Versions} ${ind + 1}`}
                        </div>
                      </td>
                      <td>{item.Name}</td>
                      <td>
                        <div className={styles.downloadContainer}>
                          <img src={download} alt="" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={styles.bgImages1}>
        <img className={styles.img11} src={img11} alt="" />
        <img className={styles.img12} src={img12} alt="" />
      </div>
      <div className={styles.bgImages2}>
        <img className={styles.img9} src={img9} alt="" />
        <img className={styles.img10} src={img10} alt="" />
      </div>
    </div>
  );
};

export default Section6;
